var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card-actions',{ref:"loadingCard",attrs:{"action-collapse":"","title":"Configuração atual do Jogo de Memória"}},[_c('validation-observer',{ref:"verificaCamposObrigatorios"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Limite de erros por partida")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Limite de erros por partida"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.consultarGame.quantidadeDeJogadas),callback:function ($$v) {_vm.$set(_vm.consultarGame, "quantidadeDeJogadas", $$v)},expression:"consultarGame.quantidadeDeJogadas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Quantidade de jogos para calcular desvio e média")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Quantidade de jogos para calcular desvio e média"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"disabled":""},model:{value:(_vm.consultarGame.quantidadeDeTestes),callback:function ($$v) {_vm.$set(_vm.consultarGame, "quantidadeDeTestes", $$v)},expression:"consultarGame.quantidadeDeTestes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"duracaoDoTeste"}},[_vm._v("Duração do jogos em minutos e segundos ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Duração do jogos em minutos e segundos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"type":"time","options":_vm.options.consultaduracaoDoTeste,"state":errors.length > 0 ? false : null,"placeholder":"00:00","disabled":""},model:{value:(_vm.consultaduracaoDoTeste),callback:function ($$v) {_vm.consultaduracaoDoTeste=$$v},expression:"consultaduracaoDoTeste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card-actions',{ref:"loadingCard",attrs:{"no-actions":"","title":"Inserção de Jogo de Memória"}},[_c('b-card-text',[_vm._v(" Nesta tela você deverá realizar as configurações que serão utilizadas no Jogo de Memória. ")]),_c('validation-observer',{ref:"verificaCamposObrigatorios"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Limite de erros por partida")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Limite de erros por partida"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.quantidadeDeJogadas),callback:function ($$v) {_vm.quantidadeDeJogadas=$$v},expression:"quantidadeDeJogadas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Quantidade de jogos para calcular desvio e média")]),_c('validation-provider',{attrs:{"rules":"required|between:1,100","name":"Quantidade de jogos  para calcular desvio e média"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.quantidadeDeTestes),callback:function ($$v) {_vm.quantidadeDeTestes=$$v},expression:"quantidadeDeTestes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"duracaoDoTeste"}},[_vm._v("Duração do jogo em minutos e segundos (máximo 23 min 59 seg) ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Duração do jogo em minutos e segundos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('cleave',{class:errors.length > 0
                          ? 'form-control is-invalid'
                          : 'form-control',attrs:{"type":"time","options":_vm.options.duracaoDoTeste,"state":errors.length > 0 ? false : null,"placeholder":"00:00"},model:{value:(_vm.duracaoDoTeste),callback:function ($$v) {_vm.duracaoDoTeste=$$v},expression:"duracaoDoTeste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"btEspaco float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.limpaCampos()}}},[_vm._v(" Limpar ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }