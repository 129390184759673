<template>
  <div>
    <!-- container de exibição do ultimo teste -->
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          action-collapse
          title="Configuração atual do Jogo de Memória"
        >
          <!-- <b-card-text>
            Ultima configuração do jogo cadastrada.
          </b-card-text> -->

          <validation-observer ref="verificaCamposObrigatorios">
            <b-form>
              <b-row>
                <!-- Limite de erros por partida -->
                <b-col md="6">
                  <b-form-group>
                    <label>Limite de erros por partida</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Limite de erros por partida"
                    >
                      <b-form-input
                        v-model="consultarGame.quantidadeDeJogadas"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Quantidade de jogos para calcular desvio e média -->
                <b-col md="6">
                  <b-form-group>
                    <label>Quantidade de jogos para calcular desvio e média</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Quantidade de jogos para calcular desvio e média"
                    >
                      <b-form-input
                        v-model="consultarGame.quantidadeDeTestes"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Duração do teste em minutos e segundos -->
                <b-col md="6">
                  <b-form-group>
                    <label
                      for="duracaoDoTeste"
                    >Duração do jogos em minutos e segundos
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Duração do jogos em minutos e segundos"
                    >
                      <cleave
                        v-model="consultaduracaoDoTeste"
                        type="time"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.consultaduracaoDoTeste"
                        :state="errors.length > 0 ? false : null"
                        placeholder="00:00"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Fecha linha, form e validation observer  -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-actions>
      </b-col>
    </b-row>

    <!-- container de inserção de teste -->
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="loadingCard"
          no-actions
          title="Inserção de Jogo de Memória"
        >
          <b-card-text>
            Nesta tela você deverá realizar as configurações que serão
            utilizadas no Jogo de Memória.
          </b-card-text>

          <validation-observer ref="verificaCamposObrigatorios">
            <b-form>
              <b-row>
                <!-- Limite de erros por partida -->
                <b-col md="6">
                  <b-form-group>
                    <label>Limite de erros por partida</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Limite de erros por partida"
                    >
                      <b-form-input
                        v-model="quantidadeDeJogadas"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Quantidade de testes para calcular desvio e média -->
                <b-col md="6">
                  <b-form-group>
                    <label>Quantidade de jogos para calcular desvio e média</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Quantidade de jogos  para calcular desvio e média"
                    >
                      <b-form-input
                        v-model="quantidadeDeTestes"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Duração do teste em minutos e segundos -->
                <b-col md="6">
                  <b-form-group>
                    <label
                      for="duracaoDoTeste"
                    >Duração do jogo em minutos e segundos (máximo 23 min 59
                      seg)
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Duração do jogo em minutos e segundos"
                    >
                      <cleave
                        v-model="duracaoDoTeste"
                        type="time"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.duracaoDoTeste"
                        :state="errors.length > 0 ? false : null"
                        placeholder="00:00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Parâmetro de desvio -->
                <!-- <b-col md="6">
                  <b-form-group>
                    <label>Parâmetro de desvio (utilize dois dígitos)</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|between:1,100"
                      name="Parâmetro de desvio (utilize dois dígitos)"
                    >
                      <cleave
                        v-model="parametroDeDesvio"
                        :class="
                          errors.length > 0
                            ? 'form-control is-invalid'
                            : 'form-control'
                        "
                        :options="options.parametroDeDesvio"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Parâmetro de desvio (utilize dois dígitos)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- Botão enviar -->
                <b-col cols="12">
                  <b-button
                    class="btEspaco float-right"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Enviar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    @click="limpaCampos()"
                  >
                    Limpar
                  </b-button>
                </b-col>

                <!-- Fecha linha, form e validation observer  -->
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import {
  BRow,
  BCol,
  BCardText,
  BFormInput,
  BFormGroup,
  BForm,
  BButton
  // BLink
} from "bootstrap-vue"
import { required, length, validate } from "@validations"
import Cleave from "vue-cleave-component"
import useJwt from "@/auth/jwt/useJwt"
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getHomeRouteForLoggedInUser } from "@/auth/utils"

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  data() {
    return {
      // parametroDeDesvio: null,
      quantidadeDeJogadas: null,
      quantidadeDeTestes: null,
      duracaoDoTeste: null,
      consultaduracaoDoTeste: null,
      tempoMaximo: null,
      consultarGame: {
        idDoGame: 0,
        quantidadeDeJogadas: 0,
        quantidadeDeTestes: 0,
        tempoMaximo: 0
      },
      options: {
        duracaoDoTeste: {
          delimiters: [":"],
          blocks: [2, 2],
          numericOnly: true
        },
        consultaduracaoDoTeste: {
          delimiters: [":"],
          blocks: [2, 2],
          numericOnly: true
        }
        // parametroDeDesvio: {
        //   delimiters: ["%"],
        //   blocks: [2, 0],
        //   numericOnly: true
        // }
      }
    }
  },
  mounted() {
    this.$refs["loadingCard"].showLoading = false
    this.getconsultarJogo()
  },
  methods: {
    // faz a chamada da api para carregar a ultima configuração salva
    async getconsultarJogo() {
      const resp = await useJwt
        .consultaJogoMemoria()
        .then(response => response.data)
        .catch(response => {
          const catchError = Object.assign({}, response)
          this.MensagemConsultarJogoMemoria(
            "danger",
            "top-center",
            catchError.response.status
          )
        })
      if (resp.sucesso) {
        this.consultarGame = resp.dados
        this.converteTempoExibicao(2)
      }
    },

    async salvarConfigMemoria() {
      // Colocar um loading aqui
      this.converteDuracao(1)

      // console.log("Entrou em salvar")
      const resp = await useJwt
        .salvarConfigMemoria({
          quantidadeDeJogadas: this.quantidadeDeJogadas,
          tempoMaximo: this.tempoMaximo,
          quantidadeDeTestes: this.quantidadeDeTestes
          // parametroDeDesvio: this.parametroDeDesvio
        })
        .then(response => response.data)
        .catch(response => {
          const erro = response
          this.MensagemErrorSalvarJogoMemoria("danger", "top-center")
        })

      if (resp.sucesso) {
        setTimeout(() => {
          // this.$router.push({
          //   name: getHomeRouteForLoggedInUser(this.$store.state.user.idDoPerfil)
          // })
        }, 2000)
        this.MensagemSalvarJogoMemoria("success", "top-center")
      }
      this.$refs["loadingCard"].showLoading = false
      setTimeout(() => {
        this.$router.go()
      }, 2000)
    },
    converteTempoExibicao(tipo) {
      if (tipo === 2) {
        const minutos = Math.floor(this.consultarGame.tempoMaximo / 60)
        const segundos = this.consultarGame.tempoMaximo % 60
        if (minutos > 9) {
          this.consultaduracaoDoTeste = String(minutos)
        } else {
          this.consultaduracaoDoTeste = "0" + String(minutos)
        }

        if (segundos > 9) {
          this.consultaduracaoDoTeste =
            this.consultaduracaoDoTeste + String(segundos)
        } else {
          this.consultaduracaoDoTeste =
            this.consultaduracaoDoTeste + "0" + String(segundos)
        }
      }
    },
    converteDuracao(tipo) {
      // Passar 1 ou 2 no parâmetro 'tipo'
      if (tipo === 1) {
        // Minutos para segundos:
        this.tempoMaximo =
          Number(this.duracaoDoTeste.slice(0, 2)) * 60 +
          Number(this.duracaoDoTeste.slice(2, 4))
        // console.log("tempoMaximo Segundos: ", this.tempoMaximo)
      } else {
        // Segundos para minutos
        const minutos = Math.floor(this.tempoMaximo / 60)
        const segundos = this.tempoMaximo % 60
        if (minutos > 9) {
          this.duracaoDoTeste = String(minutos)
        } else {
          this.duracaoDoTeste = "0" + String(minutos)
        }

        if (segundos > 9) {
          this.duracaoDoTeste = this.duracaoDoTeste + String(segundos)
        } else {
          this.duracaoDoTeste = this.duracaoDoTeste + "0" + String(segundos)
        }
      }
    },
    validationForm() {
      // console.log(this.duracaoDoTeste)
      this.$refs.verificaCamposObrigatorios.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$refs["loadingCard"].showLoading = true
          this.salvarConfigMemoria()
        }
      })
    },
    MensagemConsultarJogoMemoria(variant, position, mensagem) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Erro ao carregar os dados da ultima configuração!",
            icon: "ErrorIcon",
            variant,
            text: mensagem
          }
        },
        {
          position
        }
      )
    },
    MensagemErrorSalvarJogoMemoria(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title:
              "Erro ao salvar jogo de memória. Por favor, tente novamente.",
            icon: "ErrorIcon",
            variant,
            text:
              "Por favor, verifique se os dados enviados estão corretos ou entre em contato com o administrador."
          }
        },
        {
          position
        }
      )
    },
    MensagemSalvarJogoMemoria(variant, position) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Jogo salvo com sucesso.",
            icon: "ThumbsUpIcon",
            variant,
            text:
              "Os usuários que iniciarem uma nova sessão de jogos irão receber a nova configuração definida."
          }
        },
        {
          position
        }
      )
    },
    limpaCampos() {
      ;(this.quantidadeDeJogadas = null),
        (this.quantidadeDeTestes = null),
        (this.duracaoDoTeste = null)
    }
  }
}
</script>
<style scoped>
.btEspaco {
  margin-left: 1%;
}
</style>
